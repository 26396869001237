<template>
  <div class="reports-toolbar custom--toolbar--btns px-4">
    <div class="d-flex align-items-center">
      <toExcel
        @excel-download-emit="$emit('excel-download-emit', arguments[0])"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
// import FilterDialog from './filters'
import toExcel from './toExcel'

const currentYear = new Date().getFullYear()

import { takeFilledKeyValue } from '@/utils'
import { monthOnly, yearOnly } from '@/utils/filters/dateFormats.js'

export default {
  components: {
    // FilterDialog,
    toExcel
  },

  filters: {
    monthOnly,
    yearOnly
  },

  watch: {
    'menu.year': {
      handler(val) {
        if (val)
          setTimeout(() => (this.$refs.yearPickerRef.activePicker = 'YEAR'))
      }
    }
    // 'menu.month': {
    //   handler(val) {
    //     if (val)
    //       setTimeout(() => (this.$refs.monthPickerRef.activePicker = 'MONTH'))
    //   }
    // }
  },

  data() {
    return {
      currentYear,

      menu: {
        year: false
        // month: false
      },

      filter: {
        year: +this.$route.query.year || currentYear
        // month: +this.$route.query.month || ''
      }
    }
  },

  // beforeDestroy() {
  //   this.SET_FILTER({
  //     filter: {
  //       marka: '',
  //       get_truck_number: '',
  //       insurance: {
  //         start_date: '',
  //         end_date: ''
  //       },
  //       technical_monitoring: {
  //         start_date: '',
  //         end_date: ''
  //       },
  //       advert_permit: {
  //         start_date: '',
  //         end_date: ''
  //       },
  //       truck_oil_state: {
  //         start_usage: '', // num
  //         end_usage: '' // num
  //       },
  //       truck_tire_state: {
  //         start_usage: '', // num
  //         end_usage: '' // num
  //       },
  //       truck_accumulator_state: {
  //         start_usage: '', // num
  //         end_usage: '' // num
  //       },
  //       truck_kolodka_state: {
  //         start_usage: '', // num
  //         end_usage: '' // num
  //       }
  //     },
  //     num: '4'
  //   })
  // },

  methods: {
    ...mapMutations(['FILTER_DIALOG_TOGGLE', 'SET_FILTER']),

    onSearchByDate() {
      const props = takeFilledKeyValue(this.filter)
      this.$emit('filter:custom_dates', props)
    },

    save(dateTimeValue, datePickerPropKey) {
      // console.log(dateTimeValue, datePickerPropKey)
      let t = dateTimeValue

      if (datePickerPropKey == 'month' && t) {
        t = parseInt(dateTimeValue.slice(5))
      }

      this.filter[datePickerPropKey] = t
    },

    onInput(t = null, arg) {
      // console.log(t, arg)
      this.filter[arg] = t

      // for emit filter props to parent
      this.onSearchByDate()
    }
  }
}
</script>

<style>
.reports-toolbar {
  /* border: 1px solid #01534f; */
  height: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;
}

.custom--toolbar--btns .el-button {
  /* padding: 0px !important;
  padding: 0 0.5rem !important; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  border: 1px solid transparent;
}

.reports-toolbar > i,
.el-icon-search::before {
  color: white;
}
</style>
